import { VOLUME_ENTITY, WEIGHT_ENTITY } from '../../constants';
import {
  Check,
  CheckAttributeSpecification,
  DeviceStatus,
  IssueCode,
  ProductionRun,
  ProductionRunStatus,
  TareDeterminationMode,
  WeighingMode,
} from '../../model';
import { hasIssue } from '../issue/issue.helper';

export const hasActiveTestRunAfterStart = (productionRun: ProductionRun) => {
  return productionRun.status === ProductionRunStatus.InTestRunAfterStart;
};

export const hasActiveTestRunAfterDisruption = (productionRun: ProductionRun) => {
  return productionRun.status === ProductionRunStatus.InTestRunAfterDisruption;
};

export const hasActiveTestRun = (productionRun: ProductionRun) => {
  return (
    hasActiveTestRunAfterStart(productionRun) || hasActiveTestRunAfterDisruption(productionRun)
  );
};

export const hasActiveTareDeterminationAfterStart = (productionRun: ProductionRun) => {
  return (
    productionRun.status === ProductionRunStatus.Taring &&
    productionRun.article.tareDeterminationMode === TareDeterminationMode.AfterStart
  );
};

export const getFertigPackVStatistics = (productionRun: ProductionRun) => {
  return hasActiveTestRun(productionRun)
    ? productionRun.fertigPackVData.testRunStatistics
    : productionRun.fertigPackVData.statistics;
};

export const hasMessageFlagIssues = (productionRun: ProductionRun) => {
  const hasTU1Violation = hasIssue(productionRun, IssueCode.FertigPackVTU1Violation);
  const hasTU2Violation = hasIssue(productionRun, IssueCode.FertigPackVTU2Violation);
  const hasAverageWeightViolation = hasIssue(productionRun, IssueCode.AverageWeightBelowNominal);
  const hasAnyActiveTestRun = hasActiveTestRun(productionRun);
  const paused = isRunPausedManually(productionRun);

  if (
    paused ||
    hasTU1Violation ||
    hasTU2Violation ||
    hasAnyActiveTestRun ||
    hasAverageWeightViolation
  ) {
    return true;
  } else {
    return false;
  }
};

export const canBePaused = (productionRun: ProductionRun) => {
  const hasAnyActiveTestRun = hasActiveTestRun(productionRun);
  const hasTareDeterminationActive = hasActiveTareDeterminationAfterStart(productionRun);
  const hasCriticalTU1Violation = productionRun.fertigPackVData.hasCriticalTU1Violation;
  const hasCriticalAverageWeightBelowNominalViolation =
    productionRun.fertigPackVData.hasCriticalAverageWeightBelowNominalViolation;
  const hasTU2Violation = hasIssue(productionRun, IssueCode.FertigPackVTU2Violation);
  const isFinishing = productionRun.status === ProductionRunStatus.Finishing;
  const isDisrupted = productionRun.disrupted;

  return !(
    hasAnyActiveTestRun ||
    hasTareDeterminationActive ||
    hasCriticalTU1Violation ||
    hasTU2Violation ||
    isFinishing ||
    isDisrupted ||
    hasCriticalAverageWeightBelowNominalViolation
  );
};

export const isRunPausedManually = (productionRun: ProductionRun) => {
  const hasTU2Violation = hasIssue(productionRun, IssueCode.FertigPackVTU2Violation);

  return (
    productionRun.status === ProductionRunStatus.Paused &&
    !hasTU2Violation &&
    !productionRun.fertigPackVData.hasCriticalTU1Violation &&
    !productionRun.fertigPackVData.hasCriticalAverageWeightBelowNominalViolation
  );
};

export const getSuffix = (hasVolumeValue?: boolean) => {
  return hasVolumeValue ? VOLUME_ENTITY : WEIGHT_ENTITY;
};

/**
 * Retrieves the order number of the production run that blocks the device of `currentProductionRun`.
 *
 * @param currentProductionRun - The current ProductionRun object to evaluate.
 * @param currentDeviceStatus - The current status of the device.
 *
 * @returns {string | undefined}
 * - A string representing the order number of the blocking production run, if there is one.
 * - `undefined` if `currentProductionRun` currently does not need a device, or if `currentProductionRun` is already occupying the device or if no production run is currently associated with the device.
 */
export const getBlockingOrderNumber = (
  currentProductionRun: ProductionRun,
  currentDeviceStatus: DeviceStatus
): string | undefined => {
  var currentScale = currentProductionRun.scaleDevice;
  var currentCheck = getCurrentCheck(currentProductionRun);
  var currentCheckAttributeSpecification = currentCheck?.checkAttribute?.specification;

  if (
    currentProductionRun.status === ProductionRunStatus.Paused ||
    !currentScale?.deviceId ||
    !currentCheckAttributeSpecification ||
    ![CheckAttributeSpecification.Weight, CheckAttributeSpecification.Volume].includes(
      currentCheckAttributeSpecification
    )
  ) {
    return undefined;
  }

  const blockingProductionRun = currentDeviceStatus.productionRun;
  if (!blockingProductionRun) {
    return undefined;
  }

  if (
    blockingProductionRun.id === currentProductionRun.id ||
    (blockingProductionRun.scaleDevice?.weighingMode === WeighingMode.Manual &&
      currentScale.weighingMode === WeighingMode.Manual)
  ) {
    return undefined;
  }
  return blockingProductionRun.orderNumber;
};

export const getCurrentCheck = (productionRun: ProductionRun): Check | undefined => {
  if (productionRun.openCheckExecutions.length === 0) {
    return undefined;
  }
  const currentcheckId = productionRun.openCheckExecutions[0].productionRunCheckId;
  return productionRun.checks.find((check) => check.id === currentcheckId);
};
