import { Check, CheckAttributeType, Device, ProductionOrder, ProductionRunArticle, Scale } from '.';

export interface ProductionRunDisruption {
  id: string;
  started: string;
  resolved?: string;
  reason?: string;
  duration?: string;
}

export interface ProductionRun
  extends Omit<ProductionOrder, 'lastModified' | 'startable' | 'article' | 'order'> {
  status: ProductionRunStatus;
  started: string;
  checks: Check[];
  openCheckExecutions: OpenCheckExecution[];
  article: ProductionRunArticle;
  productionLineId?: string;
  disrupted: boolean;
  disruptions: ProductionRunDisruption[];
  checkSamplesDone: number;
  checkSamplesPassed: number;
  checkSamplesFailed: number;
  checkSamplesSufficient: number;
  checkSamplesUnrated: number;
  checkSamplesSkipped: number;
  checksDone: number;
  scaleDevice?: Scale;
  devices?: Device[];
  issues: ProductionRunIssue[];
  fertigPackVData: FertigPackVData;
  checksAppendable: boolean;
}

export interface FertigPackVData {
  statistics?: FertigPackVStatistics;
  testRunStatistics?: FertigPackVStatistics;
  hasCriticalTU1Violation?: boolean;
  hasCriticalAverageWeightBelowNominalViolation?: boolean;
}

export interface FertigPackVStatistics {
  checkSamplesDone?: number;
  checkSamplesTO2?: number;
  checkSamplesTO1?: number;
  checkSamplesOK?: number;
  checkSamplesTU1?: number;
  checkSamplesTU2?: number;
  checkSamplesAverageWeight?: number;
  checkSamplesAverageVolume?: number;
  checkSamplesAverageWeightPassed?: boolean;
  checkSamplesAverageWeightSufficient?: boolean;
  checkSamplesAverageWeightFailed?: boolean;
  checkSamplesStandardDeviation?: number;
  checkSamplesStandardVolumeDeviation?: number;
  percentageSamplesTU1?: number;
  checkSamplesGiveAwayAbsolute?: number;
  checkSamplesGiveAwayRelative?: number;
  checkSamplesWeightsAbsolute?: number;
}

export interface OpenCheckExecution {
  productionRunCheckId: string;
  productionRunCheckExecutionId: string;
  productionRunCheckCheckAttributeType: CheckAttributeType;
}

export interface ProductionRunIssue {
  issueType: IssueType;
  issueCode: IssueCode;
  issueValue?: number;
}

export enum ProductionRunStatus {
  InProduction = 1,
  Paused = 2,
  Finishing = 3,
  Done = 4,
  InTestRunAfterStart = 5,
  InTestRunAfterDisruption = 6,
  Taring = 7,
}

export enum IssueType {
  Error = 1,
  Warning = 2,
  Unknown = 99,
}

export enum IssueCode {
  AverageWeightBelowNominal = 1,
  FertigPackVTU2Violation = 2,
  FertigPackVTU1Violation = 3,
  Unknown = 99,
}
