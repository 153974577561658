import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { AppendDialogComponent } from '../../components/append-popover/append-dialog.component';
import { AverageWeightComponent } from '../../components/checks/fertigpackv-check/average-weight.component';
import { FertigPackVCategoriesComponent } from '../../components/checks/fertigpackv-check/fertigpackv-categories.component';
import { FertigPackVGiveAwayComponent } from '../../components/checks/fertigpackv-check/fertigpackv-give-away.component';
import { AverageWeightViolationDialogComponent } from '../../components/fertigpackv/average-weight-violation-dialog.component';
import { TU1ViolationDialogComponent } from '../../components/fertigpackv/tu1-violation-dialog.component';
import { IssuesComponent, IssuesElementStyle } from '../../components/issue/issues.component';
import { ContentLayoutComponent } from '../../components/layout/content-layout.component';
import { PauseDialogComponent } from '../../components/pause-popover/pause-dialog.component';
import { ProductionRunComponent } from '../../components/production/production-run.component';
import { ContainerOutside, ContainerOutsideWithHeader } from '../../components/structure';
import { getFertigPackVStatistics } from '../../helper';
import { CheckAttributeType, IssueCode, ProductionRun } from '../../model';
import {
  appendProductionRunChecks,
  calcFertigPackTolerances,
  clearFertigPackTolerances,
  openAppendProductionRunChecks,
  stopProductionRun,
} from '../../store';

import { ProductionRunPieChartComponent } from './production-run-pie-chart.component';

const PREFIX = 'ProductionRunPage';

const classes = {
  buttonGridItem: `${PREFIX}-buttonGridItem`,
  disruptionInfo: `${PREFIX}-disruptionInfo`,
  pieContainer: `${PREFIX}-pieContainer`,
  headlineWithButton: `${PREFIX}-headlineWithButton`,
};

const StyledContentLayoutComponent = styled(ContentLayoutComponent)(({ theme }) => ({
  [`& .${classes.buttonGridItem}`]: {
    textAlign: 'center',
    marginTop: theme.spacing(6),
  },

  [`& .${classes.disruptionInfo}`]: {
    display: 'flex',
    verticalAlign: 'middle',
    color: theme.palette.error.main,
    justifyContent: 'center',
  },

  [`& .${classes.headlineWithButton}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export interface OwnProps {
  productionRun: ProductionRun;
}

export const ProductionRunPage = (props: OwnProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data']);
  const { productionRun } = props;
  const statistics = getFertigPackVStatistics(productionRun);
  const hasFertigPackVWeight =
    productionRun.article.nominalValue !== undefined &&
    (!!statistics?.checkSamplesAverageWeight || !!statistics?.checkSamplesAverageVolume);
  const hasFertigPackVSamples = !!statistics?.checkSamplesDone;
  const { nominalValue, densityValue } = productionRun.article;
  const productionRunChecks = productionRun.checks;
  const appendableProductionRunChecks = productionRunChecks.filter(
    (item) =>
      item.checkPeriodicity && item.checkAttribute.checkAttributeType !== CheckAttributeType.Tare
  );
  const appendIds: string[] = [];

  for (var i = 0; i < appendableProductionRunChecks.length; i++) {
    appendIds.push(appendableProductionRunChecks[i].id);
  }

  React.useEffect(() => {
    if (nominalValue && hasFertigPackVWeight) {
      dispatch(calcFertigPackTolerances(nominalValue, densityValue));
    }
    return () => {
      dispatch(clearFertigPackTolerances());
    };
  }, [dispatch, nominalValue, hasFertigPackVWeight, densityValue]);

  const stopProductionOrderRun = (reason?: string) => {
    dispatch(stopProductionRun(productionRun.id, reason));
  };

  const appendChecks = () => {
    if (appendableProductionRunChecks.length > 1) {
      dispatch(openAppendProductionRunChecks());
    } else {
      dispatch(appendProductionRunChecks(productionRun.id, appendIds));
    }
  };

  return (
    <StyledContentLayoutComponent
      headlineText={
        productionRun.disrupted
          ? t('data:productionRun.inDisruption')
          : t('data:productionRun.noCurrentChecks')
      }
    >
      <AppendDialogComponent productionRun={productionRun} />
      <PauseDialogComponent productionRun={productionRun} />
      <TU1ViolationDialogComponent productionRun={productionRun} />
      <AverageWeightViolationDialogComponent productionRun={productionRun} />
      <div>
        <IssuesComponent
          issues={productionRun.issues}
          allowedIssueCodes={[
            IssueCode.AverageWeightBelowNominal,
            IssueCode.FertigPackVTU1Violation,
          ]}
          iconSize={'medium'}
          elementStyle={IssuesElementStyle.infobox}
        />

        <ProductionRunComponent
          productionRun={productionRun}
          stopProductionRun={stopProductionOrderRun}
          appendChecks={appendChecks}
          expanded
        />
        <Typography variant="h2">{t('data:productionRun.checkResults')}</Typography>
        <ContainerOutsideWithHeader>
          <ProductionRunPieChartComponent
            productionRun={productionRun}
            noCheckResultsText={t('data:productionRun.noCheckResults')}
          />
        </ContainerOutsideWithHeader>
        {hasFertigPackVWeight && (
          <ContainerOutside>
            <AverageWeightComponent productionRun={productionRun} />
          </ContainerOutside>
        )}
        {hasFertigPackVSamples && (
          <>
            <ContainerOutside>
              <FertigPackVCategoriesComponent productionRun={productionRun} />
            </ContainerOutside>
            <ContainerOutside>
              <FertigPackVGiveAwayComponent productionRun={productionRun} />
            </ContainerOutside>
          </>
        )}
      </div>
    </StyledContentLayoutComponent>
  );
};
