import { Box, Grid, Link, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { WEIGHT_ENTITY } from '../../../constants';
import { hasActiveTestRunAfterStart } from '../../../helper';
import {
  ProductionRunCheckExecutionWeighingSampleResult,
  TareMode,
  WeighingMode,
} from '../../../model';
import {
  checkWeightsSelector,
  hasAllRequiredCheckWeightsSelector,
  hasAllRequiredTareWeightsSelector,
  openHelpDialog,
} from '../../../store';
import { scalesStatusSelector } from '../../../store/devices/devices.selectors';
import { irisSpacing } from '../../../theme';
import { CheckAccordion } from '../../accordion/check-accordion.component';
import { ConfirmButtonComponent } from '../../buttons/confirm-button.component';
import { CheckContainerInside, CheckInfoText, InfoHeaderContainer } from '../../structure';
import {
  CheckMeasurementTableComponent,
  MeasurementColumn,
} from '../check-measurement-table.component';
import { CheckProps } from '../check.component';
import { CheckDescriptionComponent } from '../common/check.components';
import {
  checkResultRatingWeighing,
  formattedResult,
  weightMeasurementContent,
  scaleInfo,
  weightMeasurementLink,
  weightResult,
  helpButton,
  HelpButtonContainer,
} from '../common/weight-check.components';

export interface OwnProps {
  checkProps: CheckProps;
  confirmCheck: () => void;
  executeMeasurement: (sampleIndex: number, forTare: boolean) => void;
  testRunActive?: boolean;
  tareDeterminationActive?: boolean;
}

export const FertigPackVCheckMeasurementComponent = (props: OwnProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data', 'form']);
  const { checkProps, confirmCheck, executeMeasurement, testRunActive, tareDeterminationActive } =
    props;
  const { productionRun, checkAttribute } = checkProps;
  const { tareMode, tareValue: articleTareValue } = productionRun.article;
  const isIndividuallyTaring = tareMode === TareMode.IndividualTare;
  const automaticWeighing =
    productionRun.scaleDevice && productionRun.scaleDevice.weighingMode === WeighingMode.Automatic;
  const description =
    testRunActive || tareDeterminationActive ? undefined : checkAttribute.fertigPackVDescription;

  const deviceId = productionRun.scaleDevice && productionRun.scaleDevice.deviceId;
  const scalesStatus = useSelector(scalesStatusSelector);
  const currentScaleStatus = deviceId ? scalesStatus[deviceId] : undefined;
  const isScaleUnloaded = currentScaleStatus?.scaleUnloaded || false;
  const currentWeights = useSelector(checkWeightsSelector);
  const hasAllRequiredWeights = useSelector(hasAllRequiredCheckWeightsSelector);
  const hasAllRequiredTareWeights = useSelector(hasAllRequiredTareWeightsSelector);

  const handleHelpButtonClick = () => {
    dispatch(openHelpDialog());
  };

  const getSampleSize = () => {
    if (testRunActive) {
      return currentWeights ? Object.keys(currentWeights).length + 1 : 1;
    } else {
      return checkAttribute.sampleSize;
    }
  };

  const getWeighingSample = (
    sampleIndex: number
  ): ProductionRunCheckExecutionWeighingSampleResult | undefined =>
    currentWeights && currentWeights[`weight${sampleIndex}`];

  const checkInfoHeader = () => {
    return (
      <InfoHeaderContainer data-testid="checkInfoHeader">
        <CheckDescriptionComponent text={description} />
        {scaleInfo(productionRun, checkAttribute.checkAttributeType, handleHelpButtonClick)}
        {tareMode === TareMode.IndividualTare && individualTareInfo()}
      </InfoHeaderContainer>
    );
  };

  const getDetailsContent = () => {
    return testRunActive || tareDeterminationActive ? (
      <Box display={'flex'} flexDirection={'row'} gap={irisSpacing.info.space}>
        <Box display={'flex'} flexDirection={'column'} flex={1}>
          <CheckInfoText>
            {testRunActive
              ? t('data:check.executeAdjustmentChecks')
              : t('data:check.tareDeterminationDescription')}
          </CheckInfoText>
          {scaleInfo(productionRun, checkAttribute.checkAttributeType, handleHelpButtonClick)}
        </Box>
      </Box>
    ) : (
      checkInfoHeader()
    );
  };

  const individualTareInfo = () => {
    return (
      <Box sx={{ display: 'flex' }} data-testid="individualTareInfo">
        <CheckInfoText>
          <span>{t('data:check.tareDescription')}</span>
        </CheckInfoText>
        <HelpButtonContainer>{helpButton(handleHelpButtonClick)}</HelpButtonContainer>
      </Box>
    );
  };

  const testRunBottomInfo = () => {
    return (
      <Box display={'flex'} flexDirection={'column'}>
        <Typography fontWeight={'bold'}>
          {hasActiveTestRunAfterStart(productionRun)
            ? t('data:check.quitTestRunAnytimeAfterStart')
            : t('data:check.quitTestRunAnytimeAfterDisruption')}
        </Typography>
        <Typography fontWeight={'bold'}>{t('data:check.testRunResultsWillNotBeSaved')}</Typography>
      </Box>
    );
  };

  const tareCellContent = (sampleIndex: number, content?: JSX.Element) => {
    return <Box data-testid={`tareWeighing_${sampleIndex}`}>{content}</Box>;
  };

  const placeObjectElement = () => {
    return <Typography>{t('data:check.pleasePlaceObject')}</Typography>;
  };

  const tareAverage = () => {
    return (
      <Box display={'flex'} flexDirection={'row'}>
        <Typography fontWeight={'bold'}>
          {`${t('data:check.averageWeight')}`}
          &nbsp;
          {Object.keys(currentWeights).length > 0 &&
            formattedResult(articleTareValue, `${WEIGHT_ENTITY}`)}
        </Typography>
      </Box>
    );
  };

  const executeTareMeasurementButton = (sampleIndex: number) => {
    return (
      <Link
        component="button"
        variant="body2"
        underline="always"
        color="secondary"
        onClick={() => executeMeasurement(sampleIndex, true)}
        data-testid={`executeTareMeasurementBtn${sampleIndex}`}
      >
        {t('data:check.executeTareMeasurement')}
      </Link>
    );
  };

  const netResult = (sampleIndex: number) => {
    return (
      <Box data-testid={`weighing_${sampleIndex}`}>
        {weightResult(currentWeights[`weight${sampleIndex}`])}
      </Box>
    );
  };

  const measurementLink = (sampleIndex: number) => {
    const disabled = !hasAllRequiredTareWeights && tareMode === TareMode.IndividualTare;
    return weightMeasurementLink(sampleIndex, executeMeasurement, disabled);
  };

  const getMeasurementLink = (sampleIndex: number) => {
    return weightMeasurementContent(
      currentWeights[`weight${sampleIndex}`]?.weightDisplay,
      netResult(sampleIndex),
      measurementLink(sampleIndex)
    );
  };

  const getAutomaticMeasurementText = (sampleIndex: number) => {
    if (!!getWeighingSample(sampleIndex)?.weightDisplay) return netResult(sampleIndex);
    const enabled =
      isScaleUnloaded &&
      (!isIndividuallyTaring || hasAllRequiredTareWeights) &&
      (!!getWeighingSample(sampleIndex - 1)?.weightDisplay || sampleIndex === 0);

    return enabled ? (
      <Typography data-testid={`weighing_${sampleIndex}`}>
        {t('data:check.pleasePlaceObject')}
      </Typography>
    ) : (
      <></>
    );
  };

  const tareColumnContent = (sampleIndex: number) => {
    const currentTareValue = articleTareValue || getWeighingSample(sampleIndex)?.tareValue;
    const enabled =
      isScaleUnloaded && (getWeighingSample(sampleIndex - 1)?.tareValue || sampleIndex === 0);

    if (currentTareValue) {
      return tareCellContent(sampleIndex, formattedResult(currentTareValue, WEIGHT_ENTITY));
    }
    if (!automaticWeighing) {
      return executeTareMeasurementButton(sampleIndex);
    }
    if (enabled) {
      return tareCellContent(sampleIndex, placeObjectElement());
    }
    return <></>;
  };

  const checkResultRating = (sampleIndex: number) => {
    return checkResultRatingWeighing(sampleIndex, currentWeights[`weight${sampleIndex}`]);
  };

  const tareColumn: MeasurementColumn = {
    label: t('data:check.tareValueHeader'),
    content: tareColumnContent,
    width: isIndividuallyTaring ? '30%' : '20%',
  };

  const resultColumn: MeasurementColumn = {
    label: t('data:check.netResult'),
    content: automaticWeighing ? getAutomaticMeasurementText : getMeasurementLink,
    width: isIndividuallyTaring ? '30%' : '40%',
  };

  const ratingColumn: MeasurementColumn = {
    label: t('data:check.rating'),
    content: checkResultRating,
    width: '20%',
  };

  const columns = tareDeterminationActive
    ? [{ ...resultColumn, label: t('data:check.result'), width: '75%' }]
    : [tareColumn, resultColumn, ratingColumn];

  const indexColumnWidth = tareDeterminationActive ? '25%' : '20%';

  return (
    <Paper>
      <CheckAccordion
        checkAttribute={checkAttribute}
        detailsContent={getDetailsContent()}
        productionRun={productionRun}
      />
      <CheckContainerInside>
        <CheckMeasurementTableComponent
          sampleSize={getSampleSize()}
          columns={columns}
          indexColumnWidth={indexColumnWidth}
        />
        <Box mt={irisSpacing.button.mt}>
          <Grid
            container
            direction="row-reverse"
            justifyContent="space-between"
            alignItems="right"
            spacing={irisSpacing.button.space}
            flexWrap={'nowrap'}
          >
            <Grid item>
              <ConfirmButtonComponent
                handleClick={confirmCheck}
                disabled={!testRunActive && !hasAllRequiredWeights}
                customText={
                  !testRunActive
                    ? undefined
                    : hasActiveTestRunAfterStart(productionRun)
                    ? t('data:check.startProductionRun')
                    : t('data:check.continueProductionRun')
                }
              />
            </Grid>
            {testRunActive && <Grid item>{testRunBottomInfo()}</Grid>}
            {tareDeterminationActive && <Grid item>{tareAverage()}</Grid>}
          </Grid>
        </Box>
      </CheckContainerInside>
    </Paper>
  );
};
