import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, IconButton, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NumericFormat } from 'react-number-format';

import { VOLUME_ENTITY, WEIGHT_ENTITY } from '../../../constants';
import i18n from '../../../i18next';
import {
  CheckAttributeType,
  ProductionRun,
  ProductionRunCheckExecutionWeighingSampleResult,
  TareMode,
  WeighingMode,
} from '../../../model';
import { irisCustomColors } from '../../../theme';
import { CheckResultIconComponent } from '../../check-result-icon/check-result-icon.component';
import { SampleBannerWidth } from '../../sample-banner/sample-banner.component';
import { CheckInfoText } from '../../structure';

const StyledButton = styled(IconButton)(({ theme }) => ({
  color: irisCustomColors.irisGreen,
  whiteSpace: 'nowrap',
  padding: 0,
}));

export const HelpButtonContainer = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  maxHeight: theme.spacing(3),
}));

export const helpButton = (handleClick: () => void) => {
  return (
    <StyledButton data-testid="openHelpBtn" onClick={() => handleClick()}>
      <HelpOutlineIcon fontSize="large" />
      <Typography ml={1}>
        <b>{i18n.t('data:check.helpPopover.helpCaps')}</b>
      </Typography>
    </StyledButton>
  );
};
export const checkInfoTitle = (text: string, isBannerRendered?: boolean) => {
  return (
    <CheckInfoText
      variant="h3"
      style={isBannerRendered ? { width: `calc(100% - ${SampleBannerWidth}px)` } : {}}
    >
      {text}
    </CheckInfoText>
  );
};

export const scaleInfo = (
  productionRun: ProductionRun,
  checkAttributeType?: CheckAttributeType,
  onHelpButtonClick?: () => void
) => {
  const automaticWeighing = productionRun.scaleDevice?.weighingMode === WeighingMode.Automatic;
  const isHelpButtonVisible =
    automaticWeighing &&
    !!onHelpButtonClick &&
    !(
      checkAttributeType === CheckAttributeType.FertigPackV &&
      productionRun.article.tareMode === TareMode.IndividualTare
    );
  return productionRun.scaleDevice ? (
    <Box sx={{ display: 'flex' }} data-testid="scaleInfo">
      <Typography>
        {i18n.t('data:check.pleaseUseDevice')}
        <span style={{ fontWeight: 'bold' }}>
          {` ${i18n.t('data:check.scaleName', {
            scaleName: productionRun.scaleDevice!.name,
          })}`}
        </span>
        {automaticWeighing && <span>{` ${i18n.t('data:check.automaticWeighing')}`}</span>}
        {'.'}
      </Typography>
      {isHelpButtonVisible && (
        <HelpButtonContainer>{helpButton(onHelpButtonClick)}</HelpButtonContainer>
      )}
    </Box>
  ) : undefined;
};

export const formattedResult = (value?: number, suffix?: string) => {
  return value || value === 0 ? (
    <>
      <NumericFormat
        value={value}
        decimalScale={3}
        decimalSeparator={(i18n.t('form:decimalSeparator') as string) || ','}
        displayType="text"
      />
      {suffix && <span style={{ marginLeft: '5px', marginRight: '5px' }}>{`${suffix}`}</span>}
    </>
  ) : undefined;
};

export const weightResult = (sample?: ProductionRunCheckExecutionWeighingSampleResult) => {
  return (
    <>
      {sample?.volumeDisplay && (
        <>
          {formattedResult(sample?.volumeDisplay, `${VOLUME_ENTITY}`)}

          <span style={{ marginRight: '5px' }}>{`/`}</span>
        </>
      )}
      <>{formattedResult(sample?.weightDisplay, `${WEIGHT_ENTITY}`)}</>
    </>
  );
};

const linkClasses = {
  disabledLink: `weighingMeasurement-disabledLink`,
};

const LinkRoot = styled('div')(({ theme }) => ({
  [`& .${linkClasses.disabledLink}`]: {
    color: theme.palette.text.disabled,
    textDecorationColor: theme.palette.text.disabled,
    pointerEvents: 'none',
  },
}));

export const weightMeasurementLink = (
  sampleIndex: number,
  executeMeasurement: (sampleIndex: number, forTare: boolean) => void,
  disabled?: boolean
) => {
  return (
    <LinkRoot>
      <Link
        component="button"
        variant="body2"
        underline="always"
        color="secondary"
        onClick={() => executeMeasurement(sampleIndex, false)}
        data-testid={`executeMeasurementBtn${sampleIndex}`}
        disabled={!!disabled}
        className={!!disabled ? linkClasses.disabledLink : undefined}
      >
        {i18n.t('data:check.executeMeasurement')}
      </Link>
    </LinkRoot>
  );
};

export const automaticWeightMeasurement = (
  measurementEnabled: boolean,
  netResult: JSX.Element,
  sample?: ProductionRunCheckExecutionWeighingSampleResult
) => {
  return (
    <>
      {measurementEnabled && !sample ? (
        <Typography>{i18n.t('data:check.pleasePlaceObject')}</Typography>
      ) : (
        sample && <>{netResult}</>
      )}
    </>
  );
};

export const weightMeasurementContent = (
  weightDisplay: number | undefined,
  netResult: JSX.Element,
  measurementLink: JSX.Element
) => {
  return <>{weightDisplay || weightDisplay === 0 ? netResult : measurementLink}</>;
};

export const checkResultRatingWeighing = (
  sampleIndex: number,
  sample: ProductionRunCheckExecutionWeighingSampleResult | undefined
) => {
  return (
    <>
      {sample && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!!sample?.checkResult && (
            <CheckResultIconComponent key={sampleIndex} checkResult={sample!.checkResult!} />
          )}
          {sample?.fertigPackVCategory &&
            i18n.t([
              `data:check.fertigPackVCategories.${sample?.fertigPackVCategory}`,
              `data:check.fertigPackVCategories.notFound`,
            ])}
        </div>
      )}
    </>
  );
};

export const tareInfo = (tareValue: number | undefined) => {
  return (
    <Typography fontWeight={'bold'}>
      {i18n.t('data:check.tareValue', {
        tareValue: tareValue ? tareValue : '- ',
      })}
    </Typography>
  );
};
